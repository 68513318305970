import { defineComponent, ref } from "vue";

import Hero from "@/components/hero/index.vue";
import heroBg from "../../assets/images/contact-us-bg.png";

import SummitLink from "@/components/summit-link/index.vue";

import { httpService } from "../../services/http.service";

export default defineComponent({
  name: "Contact Us",
  components: { Hero, SummitLink },
  setup() {
    const formSubmitted = ref(localStorage.getItem("formSubmitted") === "true");

    const c_name = ref(null);
    const r_name = ref(null);
    const phone = ref(null);
    const email = ref(null);
    const address = ref(null);
    const responseSent = ref(false);
    const errorResponse = ref(false);

    const submitForm = () => {
      if (
        c_name.value &&
        r_name.value &&
        phone.value &&
        email.value &&
        address.value
      ) {
        httpService.post(
          `send-email?c_name=${c_name.value}&r_name=${r_name.value}&phone=${phone.value}&email=${email.value}&address=${address.value}`
        );

        c_name.value = null;
        r_name.value = null;
        phone.value = null;
        email.value = null;
        address.value = null;

        responseSent.value = true;

        // tab.value = 2;
        localStorage.setItem("formSubmitted", "true");

        setTimeout(() => {
          formSubmitted.value = "true";
          responseSent.value = false;
          // window.location.reload();
        }, 5000);
      } else {
        localStorage.removeItem("formSubmitted");

        errorResponse.value = true;

        setTimeout(() => {
          errorResponse.value = false;
        }, 4000);
      }
    };

    const heroSlide = [
      {
        title: "NSUK Student Accommodation",
        image: heroBg,
      },
    ];
    
    return {
      heroSlide,
      c_name,
      r_name,
      phone,
      email,
      address,
      submitForm,
      responseSent,
      formSubmitted,
      errorResponse,
    };
  },
  data() {
    return {
      filesrcs: [
        // 20/04/2024
        // {
        //   title:
        //     "REQUEST FOR QUALIFICATION ON THE PPP TO DEVELOP THE NASARAWA STATE UNIVERSITY KEFFI ON-CAMPUS STUDENT ACCOMMODATION",
        //   src:
        //     "https://api.nasida.na.gov.ng/downloads/RfQ-Notice-Newspaper-Ad-for-NSUK-Hostel-Procurement",
        //   time: "",
        // },
        {
          title:
            "RFQ Package on the PPP to develop the NSUK On-campus Student Accommodation",
          src:
            "https://api.nasida.na.gov.ng/downloads/FINAL_RFQ-PACKAGE-NSUK-Student-Accomodation",
          time: "",
        },
      ],
    };
  },

  mounted() {
    // Disable right-click context menu on the component
    this.$el.addEventListener('contextmenu', this.disableRightClick);
  },
  beforeUnmount() {
    // Clean up the event listener when the component is destroyed
    this.$el.removeEventListener('contextmenu', this.disableRightClick);
  },

  methods: {
    goto(url) {
      window.open(url);
    },
    disableRightClick(event) {
      event.preventDefault(); // Prevent the default context menu from appearing
    },
  },
});
