<template>
  <section class="contact-us-page" v-if="!formSubmitted">
    <Hero :slideContent="heroSlide" />

    <div class="main mx-auto max-w-screen-lg py-32 px-6 md:px-20">
      <div class="contact-form">

        <div class="form px-10 lg:p-20">
          <input type="name" v-model="c_name" placeholder="Company/Organisation Name" class="w-full bg-white p-4 py-6"
            required />
          <input type="name" v-model="r_name" placeholder="Representative Name" class="w-full bg-white p-4 py-6 my-14"
            required />
          <input type="number" v-model="phone" placeholder="Contact Number" class="w-full bg-white p-4 py-6 mb-14"
            required />
          <input v-model="email" type="email" placeholder="Email" class="w-full bg-white p-4 py-6 mb-14" required />
          <textarea required v-model="address" type="text" placeholder="Company Address" rows="3"
            class="w-full bg-white p-4 py-6 pb-32 mb-4"></textarea>
          <p class="text-center transition-all text-green-500" v-if="responseSent">Your response has been recorded and
            we will reach about to you as soon as possible</p>
          <p class="text-center transition-all text-red-500" v-if="errorResponse">Please fill all fields</p>
          <button @click="submitForm()" type="submit" class="py-4 w-full text-center mt-10 text-2xl">Submit &
            Download</button>
        </div>
      </div>
    </div>
  </section>

  <div id="our-governance" v-if="formSubmitted">
    <div class="governance_body mt-10">
      <div class="mb-10">
        <div class="sub_headings text-5xl">NSUK Student Accommodation Documents</div>
      </div>

      <div class="mt-5 max-w-screen-lg mx-auto pb-10">
        <div class="text-nasida-green">
          <!-- <h3 class="text-lg md:text-2xl">REQUEST FOR QUALIFICATION ON THE PPP TO DEVELOP THE NASARAWA STATE UNIVERSITY KEFFI ON-CAMPUS STUDENT ACCOMMODATION</h3> -->
          <embed type="application/pdf" src="https://api.nasida.na.gov.ng/services/RfQ-Notice-Newspaper-Ad-for-NSUK-Hostel-Procurement.pdf#toolbar=0&zoom=125" oncontextmenu="return false;" width="100%" height="700px" id="iframe11">
          
        </div>
      </div>

      <SummitLink v-for="f in filesrcs" @click="goto(f.src)" :key="f.title" :name="f.title" :link="f.src"
        :title="f.time"></SummitLink>
    </div>
  </div>
</template>
<!--  -->

<script src="./nsukstudentaccommodation.page.js"></script>
<style src="./nsukstudentaccommodation.page.css" scoped lang="css"></style>
